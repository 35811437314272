<template>
  <div class="index">
    <div class="nav-wallet-container">
      <ul class="nav-wallet-content">
        <li>
          <h2>
            <i class="icon-wallet icon-wallet-total"></i
            >存借总量：
          </h2>
          <p>$0</p>
        </li>
        <li>
          <h2>
            <i class="icon-wallet icon-wallet-income"></i
            >TVL：
          </h2>
          <p>$0</p>
        </li>
        <li>
          <h2>
            <i class="icon-wallet icon-wallet-price"></i
            >ROO当前价格：
          </h2>
          <p>$-</p>
        </li>
        <li>
          <h2>
            <i class="icon-wallet icon-wallet-tongji"></i
            >ROO流通量：
          </h2>
          <p>-</p>
        </li>
      </ul>
    </div>
    <div class="mining-list">
      <div class="minging-list-inner">
        <ul class="mining-list-box"></ul>
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="dialog"
        class="el-dialog DAWPop ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title"></span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="质押"
        class="el-dialog DAWPop ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title">质押</span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="提取"
        class="el-dialog DAWPop ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title">提取</span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="提示"
        class="el-dialog DAWPop ant-modal"
        style="margin-top: 0px; width: 420px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title">提示</span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    
  },
};
</script>